import { useEffect, useState } from "react";
import { resumeDownload } from "../../../service/api";

const Navigation = ({ data, subdomain }) => {
  const [visible, setVisible] = useState(false);
  const [nav, setNav] = useState(false);
  const toggleNav = function () {
    setNav(!nav);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (document.documentElement.scrollTop > 100) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    });
  }, []);

  return (
    <header
      className={
        visible
          ? "navigation fixed-top nav-bg"
          : "navigation fixed-top position-absolute"
      }
    >
      <nav className="navbar navbar-expand-lg navbar-dark">
        <a
          className="navbar-brand font-tertiary"
          href="#parallax"
          data-animate="scrolling"
        >
          {data.name}
        </a>
        <button
          className={nav ? "navbar-toggler" : "navbar-toggler collapsed"}
          type="button"
          data-toggle="collapse"
          data-target="#navigation"
          aria-controls="navigation"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={toggleNav}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={
            nav
              ? "collapse navbar-collapse text-center show"
              : "collapse text-center navbar-collapse"
          }
          id="navigation"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#parallax" data-animate="scrolling">
                Home
              </a>
            </li>
            <li className="nav-item active">
              <a className="nav-link" href="/blogs" data-animate="scrolling">
                Blogs
              </a>
            </li>
            { data.about && (
            <li className="nav-item">
              <a className="nav-link" href="#about" data-animate="scrolling">
                about
              </a>
            </li>
              )}
            <li className="nav-item">
              <a className="nav-link" href={`${resumeDownload}${subdomain}.pdf`} data-animate="scrolling">
                Download CV
              </a>
            </li>
            {data.skills?.length > 0 && Object.keys(data.skills[0]).length > 0 ||
              data.workExperience?.length > 0 && Object.keys(data.workExperience[0]).length > 0 ||
              (data.education?.length > 0 && Object.keys(data.education[0]).length > 0 && (
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#resume"
                    data-animate="scrolling"
                  >
                    Resume
                  </a>
                </li>
              ))}
            {data.projects?.length > 0 && Object.keys(data.projects[0]).length > 0 && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#portfolio"
                  data-animate="scrolling"
                >
                  Portfolio
                </a>
              </li>
            )}
            {data.recommendations?.length > 0 && Object.keys(data.recommendations[0]).length > 0 && (
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="#testimonial"
                  data-animate="scrolling"
                >
                  Testimonials
                </a>
              </li>
            )}
            <li className="nav-item">
              <a className="nav-link" href="#contact" data-animate="scrolling">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};
export default Navigation;
