import Loading from "../Components/Loading";
import styles from "../Themes/404/newLanding.module.css";

export default function Error({ errorMessage }) {
	return (
		<div className={styles.error} style={{ overflowY: "hidden" }}>
			<Loading />
			<h1>{errorMessage ?? "500 Internal Error"}</h1>
			<a href="/" className={styles.downloadExtensionOffline}>
				Go Back to Home
			</a>
		</div>
	);
}
