import { useEffect } from "react";
import filterTag from "./functions/filterTag";
import fillContent from "./functions/fillContent";
import "./css/bootstrap.scss";
import "./css/linea.scss";
import "./css/ionicons.min.scss";
import "./css/magnific-popup.scss";
import "./css/style.scss";
// import "./js/script";
import Navigation from "./components/navigation";
import Header from "./components/header";
import About from "./components/about";
import Education from "./components/education";
import Experience from "./components/experience";
import Recommendation from "./components/recommendation";
import Projects from "./components/projects";
import Contact from "./components/contact";
import Awards from "./components/awards";
import Languages from "./components/languages";
import Skills from "./components/skills";
import Interests from "./components/interests";

function Theme2({ data, subdomain }) {
	useEffect(() => {
		let metaTags = document.getElementsByTagName("meta");
		let description = filterTag(metaTags, "description");
		let author = filterTag(metaTags, "author");

		fillContent(author, data.name);
		fillContent(description, data.about);
		document.title = data.name + " | " + "SitesGPT";
	}, [data.name, data.about]);

	return (
		<div id="theme2">
			<div id="customId" className="main-content">
				<div className="page-border border-left"></div>
				<div className="page-border border-right"></div>
				<div className="page-border border-top"></div>
				<div className="page-border border-bottom"></div>
				<a href="#" className="menu-btn">
					<span className="lines">
						<span className="l1"></span>
						<span className="l2"></span>
						<span className="l3"></span>
					</span>
				</a>

				<Navigation data={data} />

				<div className="animation-block"></div>

				<div className="sections">
					<Header data={data} />

					<About data={data} subdomain={subdomain} />

					{data.skills?.length > 0 &&
					Object.keys(data.skills[0])?.length > 0 ? (
						<Skills data={data} />
					) : (
						<></>
					)}
					{data.languages?.length > 0 &&
					Object.keys(data.languages[0])?.length > 0 ? (
						<Languages data={data} />
					) : (
						<></>
					)}

					{data.awards?.length > 0 &&
					Object.keys(data.awards[0])?.length > 0 ? (
						<Awards data={data} />
					) : (
						<></>
					)}

					{data.education?.length > 0 &&
					Object.keys(data.education[0])?.length > 0 ? (
						<Education data={data} />
					) : (
						<></>
					)}
					{data.workExperience?.length > 0 &&
					Object.keys(data.workExperience[0])?.length > 0 ? (
						<Experience data={data} />
					) : (
						<></>
					)}

					<section
						id="resume"
						className="section resume-section border-d"
					>
						{data.recommendations &&
						Object.keys(data.recommendations[0]).length > 0 ? (
							<Recommendation data={data} />
						) : (
							<></>
						)}
					</section>

					{data.projects &&
					Object.keys(data.projects[0])?.length > 0 ? (
						<Projects data={data} />
					) : (
						<></>
					)}

					{data.interests &&
					Object.keys(data.interests)?.length > 0 ? (
						<Interests data={data} />
					) : (
						<></>
					)}

					<Contact data={data} />
				</div>
			</div>
		</div>
	);
}

export default Theme2;
