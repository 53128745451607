import { resumeDownload } from "../../../service/api";
const About = ({ data, subdomain }) => {
  return (
    <section id="about">
      <div className="row">
        <div className="three columns">
          <img className="profile-pic" src={data.profilePicture} alt="" />
        </div>

        <div className="nine columns main-col">
          {data.aboutMe ? (
            <>
              <h2>About Me</h2>

              <p className="about-me">{data.aboutMe}</p>
            </>
          ) : (
            <></>
          )}
          <div className="row">
            <div className="columns download">
              <p>
                <a href={`${resumeDownload}${subdomain}.pdf`} className="button">
                  <i className="fa fa-download"></i>Download Resume
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
