import "./App.css";
import Theme1 from "./Themes/Theme1reference/Theme1";
import Theme2 from "./Themes/Theme2/Theme2";
import Theme3 from "./Themes/Theme3/Theme3";
import Theme4 from "./Themes/Theme4/Theme4";
import Theme5 from "./Themes/Theme5/Theme5";
import Theme6 from "./Themes/Theme6/Theme6";
import Theme7 from "./Themes/Theme7/Theme7";
import Theme8 from "./Themes/Theme8/Theme8";
import Theme9 from "./Themes/Theme9/Theme9";

import { useEffect, useState } from "react";
import Loading from "./Components/Loading";
import Error404 from "./Themes/404/404";
import userDataJson from "./data.json";
import { getUserData } from "./service/api";
import ShareButton from "./Components/ShareButton";

function App() {
	const [userData, setUserData] = useState(null);
	// const userData =  { ...userDataJson };
	const [subdomain, setSubdomain] = useState(null);
	const shareUrl = "Checkout my website I created under 2 mins using SitesGPT: " + window.location.protocol + "//" + window.location.hostname;

	const getData = async (subdomain) => {
		const response = await getUserData(subdomain);
		if (response.data) {
			setUserData(response.data);
		} else {
			setUserData({ theme: null, json_data: {} });
		}
	};

	useEffect(() => {
		const hostnameParts = window.location.hostname.split(".");
		const subdomainIndex = hostnameParts.length > 2 ? 0 : 0;
		setSubdomain(hostnameParts[subdomainIndex]);
	}, []);

	useEffect(() => {
		if (!subdomain) return;
		getData(subdomain);
	}, [subdomain]);

	// console.log(userData);

	function ThemeRenderer() {
		// console.log(userData.json_data);
		const totalData = {...userData.jsonData, userEmail: userData.email, name: userData.jsonData?.profileName}
		// console.log('totalData', totalData);
		if (!totalData.userEmail) {
			return <Error404 />;
		}

		switch (userData.theme || 2) {
			case 1:
				return (
					<Theme1 data={totalData} subdomain={subdomain} />
				);
			case 2:
				return (
					<Theme2 data={totalData} subdomain={subdomain} />
				);
			case 3:
				return (
					<Theme3 data={totalData} subdomain={subdomain} />
				);
			case 4:
				return (
					<Theme4 data={totalData} subdomain={subdomain} />
				);
			case 5:
				return (
					<Theme5 data={totalData} subdomain={subdomain} />
				);
			case 6:
				return (
					<Theme6 data={totalData} subdomain={subdomain} />
				);
			case 7:
				return (
					<Theme7 data={totalData} subdomain={subdomain} />
				);
			case 8:
				return (
					<Theme8 data={totalData} subdomain={subdomain} />
				);
			case 9:
				return (
					<Theme9 data={totalData} subdomain={subdomain} />
				);
			default:
				return <> </>;
		}
	}
	// console.log(userData);
	return (
		<div>
			{userData ? (
				<>
					<ThemeRenderer /> <ShareButton shareUrl={shareUrl} />{" "}
				</>
			) : (
				<Loading
					style={{
						position: "fixed",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					}}
				/>
			)}
		</div>
	);
}

export default App;
