import {
	FacebookIcon,
	EmailIcon,
	LinkedinIcon,
	TwitterIcon,
	WhatsappIcon,
	RedditIcon,
	TelegramIcon,
	PinterestIcon,
} from "react-share";
import {
	EmailShareButton,
	FacebookShareButton,
	LinkedinShareButton,
	TwitterShareButton,
	WhatsappShareButton,
	RedditShareButton,
	TelegramShareButton,
	PinterestShareButton,
} from "react-share";
import { Popover } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";

export default function ShareButton({ shareUrl }) {
	const [anchorEl, setAnchorEl] = useState(null);
	const isPopoverOpen = Boolean(anchorEl);

	const showPopover = (event) => {
		if(anchorEl){
			setAnchorEl(null);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	// const hidePopover = () => {
	// 	setAnchorEl(null);
	// };

	const id = isPopoverOpen ? "simple-popover" : undefined;

	return (
		<div
			style={{
				position: "fixed",
				bottom: "20px",
				right: "20px",
				width: "50px",
				height: "50px",
				borderRadius: "50%",
				backgroundColor: "#fff",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				cursor: "pointer",
				zIndex: "1000"
			}}
			onClick={showPopover}
		>
			<ShareIcon className="share" />

			<Popover
				sx={{ backgroundColor: "transparent" }}
				id={id}
				open={anchorEl}
				anchorEl={anchorEl}
				// onClose={hidePopover}
				anchorOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<div
					className="share-button"
					style={{
						display: "flex",
						flexDirection: "column",
						backgroundColor: "transparent",
					}}
				>
					<FacebookShareButton url={shareUrl}>
						<FacebookIcon size={32} round={true} className="shareIcon" />
					</FacebookShareButton>
					<TwitterShareButton url={shareUrl}>
						<TwitterIcon size={32} round={true} className="shareIcon" />
					</TwitterShareButton>
					<EmailShareButton url={shareUrl}>
						<EmailIcon size={32} round={true} className="shareIcon" />
					</EmailShareButton>
					<WhatsappShareButton url={shareUrl}>
						<WhatsappIcon
							size={32}
							round={true}
							className="shareIcon"
						/>
					</WhatsappShareButton>
					<LinkedinShareButton url={shareUrl}>
						<LinkedinIcon
							size={32}
							round={true}
							className="shareIcon"
						/>
					</LinkedinShareButton>
					<TelegramShareButton url={shareUrl}>
						<TelegramIcon
							size={32}
							round={true}
							className="shareIcon"
						/>
					</TelegramShareButton>
					<RedditShareButton url={shareUrl}>
						<RedditIcon size={32} round={true} className="shareIcon" />
					</RedditShareButton>
					<PinterestShareButton url={shareUrl}>
						<PinterestIcon
							size={32}
							round={true}
							className="shareIcon"
						/>
					</PinterestShareButton>
					{/* <button> <ContentCopyIcon /> </button> */}
				</div>
			</Popover>
		</div>
	);
}
