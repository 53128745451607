import styles from "../Styles/DiscoverBlog.module.css";
import { useEffect, useState } from "react";
import { getUserBlogs } from "../service/api";
import ReactPaginate from "react-paginate";
import Image from "../Components/Image";
import Loading from "../Components/Loading";
import Footer from "../Components/Footer";

export default function Blogs() {
	const [blogs, setBlogs] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [pageCount, setPageCount] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(9);
	const [noBlogs, setNoBlogs] = useState(false);

	const hostnameParts = window.location.hostname.split(".");
	const subdomainIndex = hostnameParts.length > 2 ? 0 : 0;
	const subdomain = hostnameParts[subdomainIndex];

	const getBlogs = async (page, itemsPerPage) => {
		// console.log(subdomain);
		setIsLoading(true);
		const response = await getUserBlogs(subdomain, page, itemsPerPage);
		if(response.data.blogs.length){
			setBlogs(await response.data.blogs);
			setNoBlogs(false)
		}
		else{
			setNoBlogs(true)
		}
		// console.log(response.data.blogs.length);

		// if (!!response.data.blogs.length) setNoBlogs(true);

		setPageCount(response.data.totalPages);
		setIsLoading(false);

	};

	// console.log([true, true].some((e) => e === false));

	const handlePageClick = (event) => {
		setPage(event.selected + 1);
	};

	useEffect(() => {
		document.title = "SitesGPT | Blogs";
		getBlogs(page, itemsPerPage);
	}, [page, itemsPerPage]);

	return (
		<div className={styles.blogbody}>
			<div className={styles.title}>
				<h1>{subdomain} Blogs</h1>
			</div>

			{noBlogs && !isLoading && (
				<div className={styles.noBlogs}>
					<Loading style={{ margin: "auto" }} />
					{/* No blogs found */}
					Automatic blog creation has been temporarily disabled
				</div>
			)}

			<div className={styles.blogs}>
			{/* <div
					className={styles.loadingBlog}
					style={{
						margin: "auto",
					}}
			>
				Due to very overwhelming response and api limits temporarily blogs feature is unavailable.
			</div> */}
				{isLoading ? (
					<div className={styles.loadingBlog}>
						<Loading style={{ margin: "auto" }} />
						Blogs are like tortoise, they take a while to arrive but
						they win at the end.
					</div>
				) : (
					blogs.map((blog, idx) => {
						// console.log([!!blog.content, !!blog.title]);
						return (
							![!!blog.content, !!blog.title].some(
								(e) => e === false
							) && (
								<a
									key={idx}
									className={styles.blog}
									href={`/blogs/${blog.slug}`}
								>
									<h2 className={styles.blogTitle}>
										{blog.title}
									</h2>
									<Image
										className={styles.blogImg}
										src="/Assets/default.png"
										alt={blog.title}
										randomcolor={true}
									/>
									<div className={styles.blogContentWrapped}>
										{blog?.description ??
											blog?.content
												.split(" ")
												.slice(0, 20)
												.join(" ") + "..."}
									</div>
								</a>
							)
						);
					})
				)}
			</div>
			<ReactPaginate
				className={
					pageCount <= 1 ? styles.disabled : styles.paginateContainer
				}
				pageLinkClassName={styles.pageLink}
				activeLinkClassName={styles.activePageLink}
				pageClassName={styles.pages}
				activeClassName={styles.acitvePage}
				breakLabel="..."
				nextLabel="next >"
				onPageChange={handlePageClick}
				pageRangeDisplayed={5}
				pageCount={pageCount}
				previousLabel="< previous"
				renderOnZeroPageCount={null}
				previousLinkClassName={page === 1 ? styles.disabled : null}
				nextLinkClassName={page === pageCount ? styles.disabled : null}
			/>

			
			<Footer />
		</div>
	);
}
