import Education from "./education";
import Experience from "./experience";
import Skills from "./skills";
import {resumeDownload} from "../../../service/api";

const About = ({ data, subdomain }) => {
  return (
    <>
      <div className="vg-page page-about" id="about">
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="img-place wow fadeInUp">
                <img
                  className="profile-pic h-100"
                  src={data.profilePicture}
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-7 offset-lg-1 wow fadeInRight">
              <h1 className="fw-light">{data.name}</h1>
              <p className="text-muted about-me">{data.aboutMe}</p>
              <a href={`${resumeDownload}${subdomain}.pdf`}>
              <button className="btn btn-theme-outline">Download CV</button>
              </a>
            </div>
          </div>
        </div>
        <div className="container pt-5">
          <div className="row">
            {data.education.length>0 && Object.keys(data.education[0]).length > 0?<Education data={data} />:<></>}
            {data.workExperience.length>0 && Object.keys(data.workExperience[0]).length > 0?<Experience data={data}/>:<></>}
          </div>
        </div>
        {data.skills.length>0 && Object.keys(data.skills[0]).length > 0?<Skills data={data} />:<></>}
      </div>
    </>
  );
};

export default About;
