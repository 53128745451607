import styles from "../Styles/Blog.module.css";


export default function Loading(props) {
	return (
		<div className={styles.loading} {...props}>
			<svg
				id="Layer_1"
				dataname="Layer 1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 654.52 717.04"
			>
				<circle
					className={styles.cls}
					cx="304.83"
					cy="347.08"
					r="279.83"
				/>
				<path
					className={styles.cls + " " + styles.cls2}
					d="M4162.16,5253.53c-85.62,98.44-92.57,133.17-79.09,148.6c34.6,39.59,419.48-171.58,381.45-281.87c-23-66.64-224.33-16.42-240.51-85.79c-20.91-89.65,418.27-337.66,448.85-288c11.81,19.17-15.37,52.23-115.34,135.28"
					transform="translate(-4045.92 -4714.96)"
				/>
			</svg>
		</div>
	);
}
