export default function PreviewHeader({ selected, subdomain }) {
	return (
		<div
			id="swatch"
			style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
				background:"var(--primary-clr)",
				border: "1px solid white",
				padding: "0.5em 2em",
				"@media screen and (max-width: 500px) ": {
					flexDirection: "column",
				}
			}}
		>
			<div
				style={{
					opacity: "0.6",
					fontFamily: "var(--primary-font)",
				}}
			>
				Preview Page
			</div>

			{!selected ? (
				<>
					<div
						style={{
							margin: "auto",
							opacity: "0.6",
							fontFamily: "var(--primary-font)",
						}}
					>
						Do you want to change your site to this???
					</div>
					<a
						id="finish"
						style={{
                            border: "none",
                            backgroundColor: "var(--font-color-primary)",
                            color: "var(--font-color-secondary)",
                            padding: "0.5em 1em",
                            fontSize: "1rem",
							fontFamily: "var(--primary-font)",
                            textDecoration: "none",

						}}
                        href="https://app.sitesgpt.in/select-theme"
					>
						Change
					</a>
				</>
			) : (
				<div
					style={{
						margin: "auto",
						fontFamily: "var(--primary-font)",
					}}
				>
					Your site is live at:{" "}
					<a
						href={`https://${subdomain}.sitesgpt.co.in`}
						style={{
							margin: "auto",
							opacity: "0.6",
							textDecoration: "none",
							boxShadow: "0px 3px 0px -2px black",
						}}
					>
						<i>{`https://${subdomain}.sitesgpt.co.in`}</i>
					</a>{" "}
				</div>
			)}
		</div>
	);
}
