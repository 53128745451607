import Loading from "../Components/Loading";
import styles from "../Styles/Blog.module.css";
import { useEffect, useState } from "react";
import randomColor from "randomcolor";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import "github-markdown-css/github-markdown-light.css";
import PersonIcon from "@mui/icons-material/Person";
import { getBlogBySlug } from "../service/api";
import { useParams } from "react-router-dom";
import Footer from "../Components/Footer";

export default function Blog() {
	const { slug } = useParams();

	const image = "";

	const [blogData, setBlogData] = useState(null);

	const [isLoading, setIsLoading] = useState(true);

	const [userImageError, setUserImageError] = useState(false);

	useEffect(() => {
		document.title = "SitesGPT | Blog | " + blogData?.title;

		// Add meta tags
		const descriptionMetaTag = document.createElement("meta");
		descriptionMetaTag.setAttribute("name", "description");
		descriptionMetaTag.setAttribute("content", blogData?.metaDescription);

		const keywordsMetaTag = document.createElement("meta");
		keywordsMetaTag.setAttribute("name", "keywords");
		keywordsMetaTag.setAttribute("content", blogData?.metaTags);

		// Append meta tags to the head section of the HTML document
		document.head.appendChild(descriptionMetaTag);
		document.head.appendChild(keywordsMetaTag);

		// Clean up function
		return () => {
			document.head.removeChild(descriptionMetaTag);
			document.head.removeChild(keywordsMetaTag);
		};
	}, [blogData]);

	const getBlogData = async () => {
		const data = await getBlogBySlug(slug);
		const blogData = data.data.blog;

		setBlogData({
			...blogData,
			content: "#\n" + blogData.content,
			username: data.data.username,
			profileLink: data.data.profileLink,
			profileImage: data.data.profileImage,
		});
		setIsLoading(false);
	};

	useEffect(() => {
		getBlogData();
	}, []);

	return (
		<div
			className={styles.blogPageBody}
			style={{
				minHeight: "100vh",
				display: isLoading ? "flex" : "grid",
				flexDirection: "column",
			}}
		>
			{isLoading ? (
				<div
					className={styles.loadingBlog}
					style={{
						margin: "auto",
					}}
				>
					<Loading style={{ margin: "auto" }} />
					Blogs are like tortoise, they take a while to arrive but
					they win at the end.
				</div>
			) : (
				<>
					<div
						className={styles.blogPageImage}
						style={{
							backgroundColor: randomColor(),
							backgroundImage: `url(${image})`,
						}}
					></div>
					<div className={"markdown-body " + styles.blogPageContent}>
						<h1>{blogData.title}</h1>
						<div className={styles.blogPageUserData}>
							<div
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								{userImageError ? (
									<PersonIcon />
								) : (
									<img
										className={styles.userImage}
										src={blogData.profileImage}
										onError={() => setUserImageError(true)}
									/>
								)}
								<a
									href={
										"https://" +
										blogData.profileLink
											.split("/")
											.reverse()[1] +
										".sitesgpt.co.in"
									}
								>
									{blogData.username}
								</a>
							</div>
							<div>
								{new Date(
									blogData.createdAt
								).toLocaleDateString("en-US", {
									year: "numeric",
									month: "long",
									day: "numeric",
								})}
							</div>
						</div>
						<ReactMarkdown remarkPlugins={[remarkGfm]}>
							{blogData.content}
						</ReactMarkdown>
						<div className={styles.blogPageFooter}>
							{/* <div style={{backgroundColor: "#0077b5"}} >
								<a style={{color: "white"}} target="_blank" href={blogData.profileLink}>{blogData.profileLink.split("/").reverse()[1]}</a>
							</div> */}
							<div style={{ backgroundColor: "#f5c320" }}>
								Generated By LLM for{" "}
								<a
									style={{ color: "#0077b5" }}
									target="_blank"
									href={blogData.profileLink}
								>
									{
										blogData.profileLink
											.split("/")
											.reverse()[1]
									}
								</a>
							</div>
						</div>
					</div>
				</>
			)}

			<Footer />
		</div>
	);
}
