import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PreviewHeader from "./Components/PreviewHeader";
import DiscoverBlogs from "./Pages/DiscoverBlogs";
import Error404 from "./Themes/404/404";
import Blog from "./Pages/Blog";
import Error from "./Pages/Error";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <Error />

	},
	{
		path: "/preview",
		element: (
			<>
				<PreviewHeader />
				<App />
			</>
		),
		errorElement: <Error />
	},
	{
		path: "/blogs",
		element: <DiscoverBlogs />,
		errorElement: <Error />
	},
	{
		path: "/blogs/:slug",
		element: <Blog />,
		errorElement: <Error />
	},
	{
		path: "*",
		element: <App />,
		errorElement: <Error />
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
