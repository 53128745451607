import ApiRequestService from "./api.service";
import { contentTypes } from "./utils";

const ApiService = new ApiRequestService();
const baseURL = process.env.REACT_APP_API_URL

export const getUserBlogs = (subdomain, page, itemsPerPage) => {
  return ApiService.getApi(`/api/v1/user-blog/${subdomain}?page=${page}&per_page=${itemsPerPage}`,{ ...contentTypes.json });
}

export const getBlogs = (page, itemsPerPage) => {
  return ApiService.getApi(`/api/v1/blog?page=${page}&per_page=${itemsPerPage}`,{ ...contentTypes.json,});
}

export const getBlogBySlug = (slug) => {
  return ApiService.getApi(`/api/v1/blog/${slug}`,{ ...contentTypes.json});
}

export const resumeDownload = `${baseURL}/api/v1/resume/`;

export const getUserData = (subdomain) =>{
  return ApiService.getApi(`/api/v1/site/${subdomain}`,{ ...contentTypes.json,});
}