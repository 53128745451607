import { resumeDownload } from "../../../service/api";

const Header = ({data, subdomain}) => {
  return (
    <section className="hero-area section">
      <div className="container">
        <div className="row text-center">
          <div className="col-lg-8 mx-auto">
            <div className="hero-img mx-auto mb-3 position-relative">
              <img
                className="profile-pic"
                src={data.profilePicture}
                alt="img"
              />
              <span className="shape shape-1"></span>
              <span className="shape shape-2"></span>
              <span className="shape shape-3"></span>
              <span className="shape shape-4"></span>
            </div>
            <p className="mb-3 fw-medium fs-4 text-primary">
              Hello, myself <span className="name">{data.name}</span>....
            </p>
            {data.description?<p className="mb-5 tagline">{data.description}</p>:<></>}
            <div className="col-lg">
            <a href={data.aboutMe?"#about":"#skills"} className="btn btn-primary btn-lg" style={{marginRight: "30px"}}>
              Know About Me
            </a>
            <a href={`${resumeDownload}${subdomain}.pdf`} className="btn btn-primary btn-lg">
              Download CV
            </a>
            </div>
          </div>
        </div>
      </div>
      <div className="has-circle">
        <span className="circle circle-1"></span>
        <span className="circle circle-2"></span>
        <span className="circle circle-3"></span>
        <span className="circle circle-4"></span>
        <span className="circle circle-5"></span>
        <span className="circle circle-6"></span>
        <span className="circle circle-7"></span>
      </div>
    </section>
  );
};

export default Header;
